<template>
  <div id="app" ref="app" style="overflow-x: hidden">
    <Header v-if="$route.meta.show"></Header>
    <EnHeader v-if="$route.meta.show2"></EnHeader>
    <router-view></router-view>
    <div style="position: fixed; right: 10px; bottom: 144px">
      <div v-if="$route.meta.fanhui" @click="fanhui" class="fhsj">
        <div class="fanhui">
          <img src="./assets/返回上级蓝.png" alt="" />
        </div>
        <div>返回上级</div>
      </div>
    </div>
    <el-backtop :bottom="60" :right="10">
      <div class="hddb">
        <div class="dingbu">
          <img src="./assets/顶部2.png" alt="" />
        </div>

        <div v-if="$route.meta.show">回到顶部</div>
        <div v-if="$route.meta.show2">UP</div>
        <!-- <i class="el-icon-arrow-up"></i> -->
      </div>
    </el-backtop>
    <Footer v-if="$route.meta.show"></Footer>
    <EnFooter v-if="$route.meta.show2"></EnFooter>
  </div>
</template>



<script>
import _ from "lodash";
import router from '@/router';
import Header from "./components/Header";
import EnHeader from './views/En/myHome/components/Header'
import Footer from "./components/Footer";
import EnFooter from './views/En/myHome/components/Footer'
export default {
  name: "App",
  data() {
    return {
      id: 1,
    };
  },
  components: {
    Header,
    Footer,
    EnHeader,
    EnFooter
  },
  methods: {
    fanhui(){
      this.$router.go(-1)
    },
    zishiying(){
      this.$nextTick(() => {
      const $app = this.$refs.app
      const standardScale = 1080 / 1920

      window.addEventListener(
        'resize',
        _.debounce(function () {
          const docHeight = document.body.clientHeight
          const docWidth = document.body.clientWidth

          if (docWidth < 1680) {
            const currentScale = docHeight / docWidth
            let [scale, translate] = [0, 0]
            //if (currentScale < standardScale) {
              // �Ը߶ȼ���
              //scale = docHeight / 1080
              //const shouleWidth = 1920 * scale
              //const offsetWidth = docWidth - shouleWidth
              //translate = offsetWidth > 0 ? `translate(${offsetWidth / 2}px, 0)` : ''
            //} else {
              // �Կ��ȼ���
              scale = docWidth / 1920
              const shouleHeight = 1080 * scale
              const offsetHeight = docHeight - shouleHeight
              translate = offsetHeight > 0 ? `translate(0, ${offsetHeight / 2}px)` : ''
            //}
            console.log(translate)
            $app.style.cssText = `
            transform: scale(${scale});
            transform-origin: top left;
            min-width: 1920px;
            min-height: 1080px;
          `
          document.body.style.height =`${$app.offsetHeight*scale}px`;
          } else {
            $app.style.cssText = '' 
          }
        }),
        200
      )

      if (document.createEvent) {
        var event = document.createEvent('HTMLEvents')
        event.initEvent('resize', true, true)
        window.dispatchEvent(event)
      } else if (document.createEventObject) {
        window.fireEvent('onresize')
      }
    })
    // window.location.reload()
    // router.go(0);
    }
  },
  mounted () {
    this.zishiying()
    {
    this.$router.afterEach((to, from, next) => {
            window.scrollTo(0, 0)
        })
    }
    if(!navigator.language.includes('zh')) {
      document.title = 'FFTECH'
      this.$router.push("/En-US");
    } else {
      document.title = '武汉烽火富华电气有限责任公司'
    }
  },
  created() {
    // 监听路由变化
    this.$watch('$route', () => {
      // 执行刷新操作
      this.zishiying();
    });
  },
  // created(){
  //   this.zishiying()
  // }
  
};
</script>


<style scoped>
.fanhui {
}
::v-deep.el-backtop {
  width: 82px;
  height: 82px;
}
.fhsj {
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  /* text-align: center; */
  width: 82px;
  height: 82px;
  background: #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.fhsj:hover {
  cursor: pointer;
  background: #0940fd;
  color: rgba(255, 255, 255, 0.6);
  .fanhui img {
    content: url("./assets/返回上级.png");
  }
}
.hddb {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  /* text-align: center; */
  width: 82px;
  height: 82px;
  background: #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.hddb:hover {
  background: #0940fd;
  color: rgba(255, 255, 255, 0.6);
  .dingbu img {
    content: url("./assets/顶部.png");
  }
}
</style>
