// 首页
<template>
  <div v-show="topShow" id="header" class="header">
    <div style="display: flex; align-items: center">
      <div style="margin-left: 58px" class="logo">
        <a href="javascript:void(0);"
          ><img src="../img/公司logo.png" alt=""
        /></a>
      </div>
      <div class="">
        <!-- <div class="n_icon" @click="toggleNav"></div> -->
        <ul @mouseleave="mouseLeave" style="display: flex">
          <li
            v-for="(item, index) in this.titleBox"
            :key="index"
            @mouseover="mouseOverList(item)"
            class="titleCss"
            :class="{ now: activeId == index }"
            @click="go(item)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
    <a href="javascript:void(0);" style="display: flex" @click="goEnglish">
      <div class="Language">
        <img
          style="width: 30px; height: 30px"
          src="../img/语言切换.png"
          alt=""
        />切换语言
      </div>
    </a>
    <transition name="el-zoom-in-top">
      <!-- <transition name="nav-slide"> -->
      <div
        @mouseover="mouseOver2"
        @mouseleave="mouseLeave"
        v-if="isShow"
        class="MenuBar"
      >
        <!-- <div style="display: flex;"> -->
        <div class="leftCss">
          <div class="leftDiv">
            <ul>
              <li
                @mouseover.stop="mouseOver(item)"
                @mouseleave="mouseLeave2"
                @click="go(item)"
                v-for="(item, index) in leftBox"
                :key="index"
              >
                <a href="javascript:void(0);"> {{ item.title }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="!DXALshow" class="rightCss">
          <div class="rightDiv">
            <ul>
              <a href="javascript:void(0);">
                <li
                  @mouseover.stop="mouseOver(item)"
                  v-for="(item, index) in this.rightBox"
                  :key="index"
                  @click="go(item)"
                >
                  {{ item.title }}
                </li></a
              >
            </ul>
          </div>
          <div class="fgx"></div>
          <div v-if="CPJSshow" class="rightImg">
            <div
              class="imgDIV"
              v-for="(item, index) in imgBox"
              :key="index"
              @click="go(item)"
            >
              <img style="width: 125px; height: 125px;cursor: pointer;" :src="item.url" alt="" />
              <div
                style="
                  width: 125px;
                  height: 48px;
                  font-size: 18px;
                  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                  font-weight: 400;
                  color: #000000;
                  text-align: center;
                "
              >
                {{ item.title }}
              </div>
            </div>
            <a class="imgDIV">
              <div
                @click="gochanping"
                style="width: 125px; height: 125px; text-align: center;cursor: pointer;"
              >
                <img
                  style="width: 30px; height: 6px; margin-top: 50%"
                  src="../img/产品查看更多.png"
                  alt=""
                />
              </div>

              <div
                style="
                  width: 125px;
                  height: 48px;
                  font-size: 18px;
                  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                  font-weight: 400;
                  color: #000000;
                  text-align: center;
                "
              >
                查看更多
              </div>
            </a>
          </div>
          <div
            v-if="JJFAshow"
            style="width: 66%; display: flex; flex-direction: column"
          >
            <div
              style="
                padding: 4%;
                font-size: 18px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: color: rgba(0, 0, 0, 0.6);;
                line-height: 36px;
                text-indent: 2em;
                height: 150px;
              "
            >
              <!-- aaaa -->
              {{ this.JJFAdata.title }}
            </div>
            <img class="jjfangantu" :src="this.JJFAdata.url" alt="" />
          </div>
        </div>

        <!-- 典型案例导航 -->
        <div v-if="DXALshow" class="rightCss">
          <div style="width: 100%; display: flex; flex-direction: column">
            <ul>
              <a>
                <li
                  style="
                    font-size: 18px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.6);
                    line-height: 36px;
                    text-align: end;
                    margin-left: 3%;
                    margin-right: 3%;
                    margin-top: 2%;
                  "
                  v-for="(item, index) in this.rightBox"
                  :key="index"
                  @click="go(item)"
                >
                  <a>{{ item.title }}</a>
                </li>
              </a>
            </ul>
            <img class="daohangxiaotu" :src="this.JJFAdata.url" alt="" />
          </div>
        </div>

        <!-- </div> -->
      </div>
    </transition>

    <!-- 右侧 -->
    <el-drawer
      size="70%"
      title=""
      :visible.sync="drawer"
      :before-close="handleClose"
    >
      <ul v-for="(item, index) in titleBox" :key="index">
        <li
          @click="go(item)"
          style="
            font-weight: bolder;
            margin-left: 3%;
            margin-right: 3%;
            font-size: 16px;
            padding: 15px 0;
            border-bottom: 1px solid #d4d4d4;
            line-height: 20px;
            color: #333333;
            font-size: 1em;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          "
        >
          <a href="javascript:void(0);"
            >{{ item.title }}
            <i style="float: right" class="el-icon-caret-right"></i>
          </a>
        </li>
      </ul>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "MyHeader",
  data() {
    return {
      CPJSshow: false,
      JJFAshow: false,
      DXALshow: false,
      topShow: true,
      scrollTop: 0,
      drawer: false,
      timeoutId: null,
      isShow: false,
      leftBox: [],
      rightBox: [],
      JJFAdata: {
        title:
          "公司提供分布式光伏电站、集中式光伏电站、风光互补电站、风力发电站等新能源发电项目可研、方案、设计、设备选型采购、项目管理及施工、后期运维全生命周期的新能源发电服务。",
        url: require("../img/新能源.png"),
      },

      imgBox: [],
      activeId: "0",
      titleBox: [
        { title: "首页", router: "/Home" },
        { title: "产品介绍", router: "/product" },
        // { title: "产品资料", router: "/ziliao" },
        { title: "解决方案", router: "/solution" },
        { title: "典型案例", router: "/typicalCase" },
        { title: "新闻动态", router: "/News" },
        { title: "电商平台", router: "" },
        { title: "关于我们", router: "" },
        // { title: "联系我们", router: "/ContactUs" },
        // { title: "加入我们", router: "/joinUs" },
      ],
    };
  },
  methods: {
    handleScroll() {
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(this.scrollTop,'this.scrollTop');
      if (this.scrollTop > 250) {
        // 隐藏顶部导航栏
        this.topShow = false;
      } else {
        // 显示顶部导航栏
        // ...
        this.topShow = true;
      }
    },
    handleClose(done) {
      done();
    },
    mouseLeave2() {
      // this.rightBox=[]
    },
    mouseOver2() {
      clearTimeout(this.timeoutId);
      this.isShow = true;
    },
    // 鼠标移入
    mouseOver(val) {
      console.log(val, "vvvv");
      clearTimeout(this.timeoutId);
      // 产品方案一级
      if (val.title == "储能系列产品") {
        this.rightBox = [
          { title: "户用储能系列产品", router: "/product" },
          { title: "便携式储能系列产品", router: "/product" },
          { title: "通信储能系列产品", router: "/product" },
          { title: "工商业储能系列产品", router: "/product" },
          { title: "集中式储能系列产品", router: "/product" },
        ];
        this.imgBox = [
          {
            title: "单相混合式逆变器",
            url: require("@/views/product/img/单相2.png"),
            router: "/ProductDetails",
          },
          {
            title: "三相混合式逆变器",
            url: require("@/views/product/img/三相1.png"),
            router: "/sanxiangnibianqi",
          },
          {
            title: "低压储能电池",
            url: require("@/views/product/img/低压电池1.png"),
            router: "/diyadianchizu",
          },
          {
            title: "高压储能电池",
            url: require("@/views/product/img/高压电池1.png"),
            router: "/gaoyadianchizu",
          },
        ];
      } else if (val.title == "新能源汽车充电桩系列产品") {
        this.rightBox = [
          { title: "交流充电桩", router: "/product" },
          { title: "直流充电桩", router: "/product" },
          // { title: "充电桩运营管理平台", router: "/product" },
        ];
        this.imgBox = [
          {
            title: "壁挂式交流充电桩",
            url: require("@/views/product/img/交流壁挂.png"),
            router: "/jiaoliuchongdianzhuang",
          },
          {
            title: "立柱交流充电桩",
            url: require("@/views/product/img/落地式交流充电桩.png"),
            router: "/jiaoliuchongdianzhuang",
          },
        ];
      } else if (val.title == "智能化系列产品") {
        this.rightBox = [
          { title: "智慧门禁系列产品", router: "/product" },
          { title: "智慧停车系列产品", router: "/product" },
          { title: "智慧照明系列产品", router: "/product" },
          { title: "智慧楼控系列产品", router: "/product" },
          { title: "建筑能耗管理系统", router: "/product" },
          { title: "智慧建筑集成管理平台", router: "/product" },
          { title: "智慧多功能杆系列产品", router: "/product" },
        ];
        this.imgBox = [];
      } else if (val.title == "智慧能源") {
        this.rightBox = [
          { title: "新能源发电解决方案", router: "/newEnergy" },
          { title: "微电网解决方案", router: "/microgrid" },
          { title: "储能解决方案", router: "/StoredEnergy" },
        ];
      } else if (val.title == "智慧城市") {
        this.rightBox = [
          { title: "城市静态交通解决方案", router: "/chengshijt" },
          { title: "城市交管解决方案", router: "/jiaoguan" },
          { title: "智慧高速交通解决方案", router: "/gaoshu" },
        ];
      }
      //二级
      if (val.title == "户用储能系列产品") {
        this.imgBox = [
          {
            title: "单相混合式逆变器",
            url: require("@/views/product/img/单相2.png"),
            router: "/ProductDetails",
          },
          {
            title: "三相混合式逆变器",
            url: require("@/views/product/img/三相1.png"),
            router: "/sanxiangnibianqi",
          },
          {
            title: "低压储能电池",
            url: require("@/views/product/img/低压电池1.png"),
            router: "/diyadianchizu",
          },
          {
            title: "高压储能电池",
            url: require("@/views/product/img/高压电池1.png"),
            router: "/gaoyadianchizu",
          },
        ];
      } else if (val.title == "便携式储能系列产品") {
        this.imgBox = [
          {
            title: "600W户外电源",
            url: require("@/views/product/img/600w户外.png"),
            router: "/600Whuwai",
          },
          {
            title: "1200W户外电源",
            url: require("@/views/product/img/1200w户外.png"),
            router: "/1200Whuwai",
          },
        ];
      } else if (val.title == "通信储能系列产品") {
        this.imgBox = [
          {
            title: "基站备电用磷酸铁锂电池",
            url: require("@/views/product/img/通信储能.png"),
            router: "/jizhanbeiyong",
          },
        ];
      }  else if (val.title == "工商业储能系列产品") {
        this.imgBox = [
          {
            title: "工商业储能-风冷",
            url: require("@/views/product/img/工商业储能-风冷.png"),
            router: "/fenglengchuneng",
          },
          {
            title: "工商业储能-液冷",
            url: require("@/views/product/img/工商业储能-液冷.png"),
            router: "/yelengchuneng",
          },
        ];
      } else if (val.title == "集中式储能系列产品") {
        this.imgBox = [
          {
            title: "风冷储能系统",
            url: require("@/views/product/img/集装箱储能.png"),
            router: "/jizhuangxiang",
          },
          {
            title: "液冷储能系统",
            url: require("@/views/product/img/液冷储能.png"),
            router: "/yelenchuneng",
          },
          {
            title: "升压一体机",
            url: require("@/views/product/img/升压一体机.png"),
            router: "/shengyayitiji",
          },
        ];
      } else if (val.title == "交流充电桩") {
        this.imgBox = [
          {
            title: "壁挂式交流充电桩",
            url: require("@/views/product/img/交流壁挂.png"),
            router: "/jiaoliuchongdianzhuang",
          },
          {
            title: "立柱交流充电桩",
            url: require("@/views/product/img/落地式交流充电桩.png"),
            router: "/jiaoliuchongdianzhuang",
          },
        ];
      } else if (val.title == "直流充电桩") {
        this.imgBox = [
          {
            title: "200-360kW分体桩充电桩",
            url: require("@/views/product/img/200-360kW分体桩.png"),
            router: "/fentizhiliu",
          },
          {
            title: "480-600kW分体式充电桩",
            url: require("@/views/product/img/480-600kW分体式充电桩.png"),
            router: "/fentizhiliu",
          },
          {
            title: "壁挂直流充电桩",
            url: require("@/views/product/img/壁挂直流桩.png"),
            router: "/guabizhiliu",
          },
          {
            title: "一体式直流充电桩",
            url: require("@/views/product/img/一体式直流桩02.47.png"),
            router: "/yitizhiliu",
          },
          {
            title: "高压液冷充电桩",
            url: require("@/views/product/img/高压液冷.png"),
            router: "/gaoyayeleng",
          },
        ];
      } else if (val.title == "充电桩运营管理平台") {
        this.imgBox = [
          {
            title: "充电桩运营管理平台",
            url: require("@/views/product/img/平台.png"),
          },
        ];
      }else if (val.title == "中国制造网") {
        this.JJFAdata={title:
            "全系列产品，便捷购买，国内国际市场全覆盖。",
          url: require("../img/制造网.png"),}
      }else if (val.title == "淘宝") {
        this.JJFAdata={title:
            "全系列产品，便捷购买，国内国际市场全覆盖。",
          url: require("../img/淘宝.png"),}
      }
      else if (val.title == "智慧城市") {
        this.JJFAdata={title:
            "公司提供智慧建筑、智慧园区、智慧交通等智能化项目的设计、设备选型采购、项目管理及施工、后期运维全生命周期的整体解决方案。",
          url: require("../img/智慧城市.png"),}
      }
      else if (val.title == "智慧能源") {
        this.JJFAdata={title:
            "公司提供分布式光伏电站、集中式光伏电站、风光互补电站、风力发电站等新能源发电项目可研、方案、设计、设备选型采购、项目管理及施工、后期运维全生命周期的新能源发电服务。",
          url: require("../img/新能源.png"),}
      }
    },
    //导航栏移入
    mouseOverList(index) {
      clearTimeout(this.timeoutId);
      if (index.title == "首页") {
        this.isShow = false;
        this.leftBox = [];
        this.rightBox = [];
      } else if (index.title == "产品介绍") {
        this.isShow = true;
        this.CPJSshow = true;
        this.JJFAshow = false;
        this.DXALshow = false;
        this.leftBox = [
          { title: "储能系列产品", router: "/product" },
          { title: "新能源汽车充电桩系列产品", router: "/product" },
          { title: "智能化系列产品", router: "/product" },
        ];
        this.rightBox = [
          { title: "户用储能系列产品", router: "/product" },
          { title: "便携式储能系列产品", router: "/product" },
          { title: "通信储能系列产品", router: "/product" },
          { title: "工商业储能系列产品", router: "/product" },
          { title: "集中式储能系列产品", router: "/product" },
        ];
        this.imgBox = [
          {
            title: "单相混合式逆变器",
            url: require("@/views/product/img/单相2.png"),
            router: "/ProductDetails",
          },
          {
            title: "三相混合式逆变器",
            url: require("@/views/product/img/三相1.png"),
            router: "/sanxiangnibianqi",
          },
          {
            title: "低压储能电池",
            url: require("@/views/product/img/低压电池1.png"),
            router: "/diyadianchizu",
          },
          {
            title: "高压储能电池",
            url: require("@/views/product/img/高压电池1.png"),
            router: "/gaoyadianchizu",
          },
        ];
      } else if (index.title == "解决方案") {
        this.isShow = true;
        this.JJFAshow = true;
        this.CPJSshow = false;
        this.DXALshow = false;
        this.leftBox = [
          { title: "智慧能源", router: "/solution" },
          { title: "智慧城市", router: "/solution" },
        ];
        this.rightBox = [
          { title: "新能源发电解决方案", router: "/newEnergy" },
          { title: "微电网解决方案", router: "/microgrid" },
          { title: "储能解决方案", router: "/StoredEnergy" },
        ];
        this.JJFAdata = {
          title:
            "公司提供分布式光伏电站、集中式光伏电站、风光互补电站、风力发电站等新能源发电项目可研、方案、设计、设备选型采购、项目管理及施工、后期运维全生命周期的新能源发电服务。",
          url: require("../img/新能源.png"),
        };
      } else if (index.title == "典型案例") {
        this.isShow = false;
        this.leftBox = [];
        this.rightBox = [];
        // this.isShow = true;
        // this.DXALshow = true;
        // this.JJFAshow = false;
        // this.CPJSshow = false;
        // this.leftBox = [
        //   { title: "智慧建筑典型案例", router: "/Building-case" },
        //   { title: "智慧能源典型案例", router: "/Energy-case" },
        //   { title: "智慧交通典型案例", router: "/Traffic-case" },
        // ];
        // this.rightBox = [
        //   { title: "枣庄双子星广场智慧楼宇" },
        //   { title: "横店街平安城镇/智慧社区及智能交通" },
        //   { title: "国网湖北计量中心泛在电力物联网系统及软件平台" },
        //   { title: "武汉舵落口广场" },
        //   { title: "中南设计研究总院有限公司设计大厦" },
        // ];
      } else if (index.title == "新闻动态") {
        this.isShow = false;
        this.leftBox = [];
        this.rightBox = [];
      } else if (index.title == "电商平台") {
        this.isShow = true;
        this.DXALshow = false;
        this.JJFAshow = true;
        this.CPJSshow = false;
        this.leftBox = [
          { title: "中国制造网", router: "" },
          { title: "淘宝", router: "" },
        ];
        this.rightBox = [];
        this.JJFAdata={title:
            "全系列产品，便捷购买，国内国际市场全覆盖。",
          url: require("../img/制造网.png"),}
      }
       else if (index.title == "关于我们") {
        this.isShow = true;
        this.DXALshow = false;
        this.JJFAshow = true;
        this.CPJSshow = false;
        this.leftBox = [
            { title: "技术支持", router: "/ziliao" },
          { title: "联系我们", router: "/ContactUs" },
          { title: "加入我们", router: "/joinUs" },
        ];
        this.rightBox = [];
        this.JJFAdata = {
          title:
            "公司聚焦智慧能源与智慧城市两大领域，业务覆盖储能（包括集中式储能、工商业储能、分布式储能、户用储能、移动储能等）、电动汽车充电桩（直流充电桩与交流充电桩等）、换电站、新能源发电、智能配用电、智慧建筑、智慧安防、智慧园区、智慧交通等相关业务的产品开发及整体解决方案。 作为国家级高新技术企业，公司始终坚持布局长远、追求卓越，以持续创新赋能高质量发展，积极培育人工智能、大数据、云计算、区块链等新⼀代信息技术，成为智慧能源与智慧城市领域领先的产品及解决方案提供商。",
          url: require("../img/智慧城市.png"),
        };
      }
       else if (index.title == "产品资料") {
        this.isShow = false;
        this.leftBox = [];
        this.rightBox = [];
      }
       else if (index.title == "加入我们") {
        this.isShow = false;
        this.leftBox = [];
        this.rightBox = [];
      }
    },

    // 鼠标移出
    mouseLeave(val) {
      this.timeoutId = setTimeout(() => {
        this.isShow = false;
      }, 500); // 延迟500毫秒后隐藏导航栏
      // this.leftBox=[]
    },
    //路由跳转事件
    go(index) {
      this.$router.push(index.router);
      console.log(index);
      if (index.title == "淘宝") {
        window.open(
          "https://shop283530163.taobao.com/?spm=a230r.7195193.1997079397.2.294746bf338PI6",
          "_blank"
        );
      } else if (index.title == "中国制造网") {
        window.open("https://ffepower.en.made-in-china.com/", "_blank");
      }
    },
    gochanping() {
      this.$router.push("/product");
    },
    toggleNav() {
      // $(".nav ").slideToggle();
      this.drawer = true;
    },

    handleCommand(command) {
      if (command == "b") {
        this.$router.push("/En-US");
      }
    },
    goEnglish() {
      document.title = 'FFTECH'
      this.$router.push("/En-US");
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>


<style scoped>
.titleCss {
  width: 200px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.titleCss {
  font-size: 20px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
  line-height: 0px;
}
.titleCss:hover {
  color: #0940fd;
  border-bottom: 4px solid #0940fd;
}
.imgDIV {
  margin-left: 1%;
  margin-top: 2%;
}
.el-zoom-in-top-enter-active,
.el-zoom-in-top-leave-active {
  transition: transform 0.6s;
}

.el-zoom-in-top-enter,
.el-zoom-in-top-leave-to {
  opacity: 0;
}
.nav-slide-enter-active,
.nav-slide-leave-active {
  transition: transform 0.5s ease-in-out;
}

.nav-slide-enter,
.nav-slide-leave-to {
  transform: translateY(-100%);
}
::v-deep .head .logo img {
  width: 198px;
  height: 35.41px;
}
.Language {
  /* background: url("../img/语言切换.png") no-repeat; */
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  position: absolute;
  top: 32%;
  right: 3%;
  /* overflow-x: auto; */
}
@media screen and (max-width: 420px) {
  .Language {
    position: absolute;
    margin-top: -3%;
    margin-left: 1%;
  }
}
@media screen and (max-width: 780px) {
  .Language {
    position: absolute;
    margin-top: 0%;
    /* margin-top: 30px; */
    margin-left: 50%;
  }
  ::v-deep .head .logo img {
    width: 198px;
    height: 35.41px;
    vertical-align: top;
  }
}
@media screen and (max-width: 390px) {
  .Language {
    position: absolute;
    margin-top: 0%;
    margin-left: 50%;
  }
}
@media screen and (max-width: 330px) {
  .Language {
    position: absolute;
    margin-top: 0%;
    margin-left: 50%;
  }
}
::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 60px;
  line-height: 45px;
  border-bottom: 2px solid transparent;
  color: #909399;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  font-size: 18px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.header {
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 9999; /* 使导航栏处于最上层 */
  background-color: rgba(214, 208, 208, 0.3);
}
/* .nav_m {
  padding-left: 5%;
} */
.n_icon {
  right: 10%;
}

.li_color {
  color: orange;
}
.line {
  position: absolute;
  width: 1px;
  height: 260px;
  border-left: 1px solid #5f9edc;
  top: 10px;
  left: 25%;
}
.line4 {
  position: absolute;
  width: 1px;
  height: 180px;
  border-left: 1px solid #5f9edc;
  top: 10px;
  left: 25%;
}
.line2 {
  position: absolute;
  width: 1px;
  height: 260px;
  border-left: 1px solid #5f9edc;
  top: 10px;
  left: 50%;
}
.line3 {
  position: absolute;
  width: 1px;
  height: 260px;
  border-left: 1px solid #5f9edc;
  top: 10px;
  left: 75%;
}
.MenuBar {
  display: flex;
  /* margin-top: 0.5%; */
  margin-left: 16%;
  width: 68%;
  height: 546px;
}
@media screen and (max-width: 780px) {
  .MenuBar {
    display: none;
  }
}
.leftDiv {
  /* margin-left: 6%; */
}
.leftDiv li {
  padding: 7%;
  height: 78px;
  width: 100%;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.leftDiv li:hover {
  /* margin-top: 10%;
  margin-right: 15%; */
  font-size: 18px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  background: #ffffff;
  border-radius: 12px 0px 0px 12px;
}
.leftDiv li a:hover {
  margin-top: 10%;
  margin-right: 15%;
  font-size: 18px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}

.rightDiv {
  width: 36.5%;
  /* margin-left: 10%; */
}
.fgx {
  margin-left: -2.5%;
  border-left: 1px solid #e0dcdc;
  height: 95%;
  margin-top: 2%;
  display: inline-block;
  vertical-align: middle;
}
.rightDiv ul {
  display: flex;
  flex-direction: column;
}
.leftDiv ul {
  display: flex;
  flex-direction: column;
}
.rightDiv li {
  font-weight: bolder;
  padding: 7%;
  height: 78px;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.rightDiv li:hover {
  font-size: 18px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #0940fd;
}
.rightDiv li:hover::after {
  font-size: 18px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #0940fd;
  content: "";
  float: right;
  border-left: 5px solid #0940fd;
  height: 28px;
}

.rightDiv a:hover {
  font-size: 18px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #0940fd;
}
.leftCss {
  width: 26.7%;
  background-color: rgba(247, 249, 255, 1);
  border-radius: 12px 0px 0px 12px;
}

.rightCss {
  width: 73.3%;
  display: flex;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0px 12px 12px 0px;
}
.leftDiv li::after {
  content: "";
  float: right;
  background-image: url("../img/导航栏左侧箭头.png");
  display: block;
  width: 24px;
  height: 24px;
  background-size: 100% 100%;
  margin-top: 4px;
}

.rightImg {
  /* margin: 7%; */
  width: 66%;
  display: flex;
  flex-wrap: wrap;
}
.daohangxiaotu {
  width: 267px;
  height: 162px;
  position: absolute;
  margin-top: 18%;
  margin-left: 34%;
  border-radius: 15px;
  cursor: pointer;
}
.jjfangantu {
  width: 267px;
  height: 162px;
  /* position: absolute; */
  margin-top: 30%;
  margin-left: 50%;
  margin-bottom: 46px;
  border-radius: 15px;
  cursor: pointer;
}
@media (min-width: 1020px) and (max-width: 1122px) {
  .head .nav li a {
    width: 78px;
    /* display: inline; */
  }
}
/*大于等于px，并且小于等于 px */
@media (min-width: 1480px) and (max-width: 1810px) {
  /* .titleCss {
    width: 140px;
    height: 80px;
    text-align: center;
  } */
  /* .jjfangantu {
    width: 267px;
    height: 162px;
    margin-top: 30%;
    margin-left: 30%;

    border-radius: 15px;
  } */
}
/*大于等于px，并且小于等于 px */
@media (min-width: 1308px) and (max-width: 1479px) {
  /* .titleCss {
    width: 110px;
    height: 80px;
    text-align: center;
  } */
  /* .jjfangantu {
    width: 267px;
    height: 162px;
    margin-top: 30%;
    margin-left: 30%;

    border-radius: 15px;
  } */
}
@media (min-width: 1025px) and (max-width: 1308px) {
  /* .titleCss {
    width: 100px;
    height: 80px;
    text-align: center;
  } */
}
@media (max-width: 1308px) {
  .Language {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    position: absolute;
    top: 39%;
    right: 5%;
  }
  .n_icon {
    right: 20%;
  }
}
@media (max-width: 770px) {
  .n_icon {
    right: 30%;
  }
}
</style>
